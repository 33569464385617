import React from 'react';
import classnames from 'classnames';
import Slider from '@ant-design/react-slick';
import './slick.scss';

import { cardRenderSizes } from '../../../util/listingCard';

import RightIcon from '../../../components/LeftIcon/LeftIcon';
import LeftIcon from '../../../components/RightIcon/RightIcon';
import { ListingCard } from '../../../components';

import css from './SectionSponsoredListings.module.scss';

function SampleNextArrow(props) {
  const {
    className,
    style,
    onClick,
  } = props;

  const isDisabled = className.includes('slick-disabled');

  return (
    <div
      className={className}
      style={{
        ...style,
        top: 150,
        display: isDisabled ? 'none' : 'block',
      }}
      onClick={onClick}
    >
      <div className={css.button}>
        <RightIcon />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const {
    className,
    style,
    onClick,
  } = props;

  const isDisabled = className.includes('slick-disabled');

  return (
    <div
      className={className}
      style={{
        ...style,
        left: -47,
        top: 150,
        display: isDisabled ? 'none' : 'block',
      }}
      onClick={onClick}
    >
      <div className={css.button}>
        <LeftIcon />
      </div>
    </div>
  );
}


const DesktopListings = (props) => {
  const {
    metadata,
    listings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  return (
    <div className={css.listingsWrapper}>
      <Slider
        className={classnames(css.slider, {
          [css.centeredSlider]: listings.length < 4,
        })}
        infinite={false}
        speed={500}
        slidesToShow={4}
        slidesToScroll={4}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
      >
        {listings.map(l => (
          <ListingCard
            airplaneMetadata={metadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </Slider>
    </div>
  );
};

export default DesktopListings;
